
export default {
  //接收对象参数
  props: {
    listObject: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      newList: [],
      bg: this.$myPlugin.imgOssUrl + "history_time_bg.png",
      swiperOption: {
        circular: true,
        loop: false,
        autoplay: {
          delay: 4500,
          disableOnInteraction: false, //用户操作轮播后继续动画自动轮播
        },
        slidesPerView: "auto",
        centeredSlides: true,
        spaceBetween: 0,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          dynamicBullets: false,
        },
        navigation: {
          nextEl: ".swiper-button-next", // 下一页dom节点
          prevEl: ".swiper-button-prev", // 前一页dom节点
        },
      },
    };
  },
  methods: {
    //组装数据
    getList() {
      for (let i = 0; i < this.listObject.dynamicElement.length; i += 4) {
        this.newList.push(this.listObject.dynamicElement.slice(i, i + 4));
      }
    },
  },
  mounted() {
    this.getList();
  },
};
