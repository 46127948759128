import Vue from "vue";
// 百度自带客服sdk接入
Vue.prototype.$openService = function () {
  // 查找已存在的iframe
  let existingIframe = document.querySelector(
    "iframe[src='https://wwads.cn/code/tracking/160']"
  );
  // 如果不存在，则创建一个新的iframe
  if (!existingIframe) {
    let iframe = document.createElement("iframe");
    iframe.src = "https://wwads.cn/code/tracking/160";
    iframe.style.width = "0";
    iframe.style.height = "0";
    iframe.style.border = "0";
    document.body.appendChild(iframe);
  }
  const params = {
    url: "https://affim.baidu.com/unique_51132943/chat?siteId=19844856&userId=51132943&siteToken=937da57bb99ad6cfc24b2e24bd10b13c&cp=https%3A%2F%2Fwww.73app.cn%2F&cr=&cw=",
    title: "",
    width: "880",
    height: "690",
  };
  let url = params.url; //转向网页的地址;
  let name = params.title || ""; //网页名称，可为空;
  let iWidth = params.width || "900"; //弹出窗口的宽度;
  let iHeight = params.height || window.innerHeight * 0.8; //弹出窗口的高度;
  let iTop = (window.screen.height - 30 - iHeight) / 2; //获得窗口的垂直位置;
  let iLeft = (window.screen.width - 10 - iWidth) / 2; //获得窗口的水平位置;
  window.open(
    url,
    name,
    "height=" +
      iHeight +
      ",,innerHeight=" +
      iHeight +
      ",width=" +
      iWidth +
      ",innerWidth=" +
      iWidth +
      ",top=" +
      iTop +
      ",left=" +
      iLeft +
      ",toolbar=no,menubar=no,scrollbars=auto,resizeable=no,location=no,status=no"
  );
};

/***
      window.qimoChatClick();
 *    七陌客服 //暂时不用 在nuxt.config.js注视到js
 *  */
