
export default {
  props: {
    listObject: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      hstatus: false,
    };
  },
};
