
export default {
  //接收对象参数
  props: {
    listObject: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      newList: [],
    };
  },
  methods: {
    getList() {
      for (let i = 0; i < this.listObject.dynamicElement.length; i += 6) {
        this.newList.push(this.listObject.dynamicElement.slice(i, i + 6));
      }
    },
  },
  mounted() {
    //拿到数据
    this.getList();
  },
};
