
import CommonTemplate from "@/components/common-template.vue";
import { queryNavList } from "@/api/header";
import { queyHtmlMoudle } from "@/api/index";
import { footerNav } from "@/api/footer";
import CommonLinks from "@/components/CommonLinks.vue";
import CommonHearder from "~/components/common-hearder.vue";

export default {
  scrollToTop: true,

  name: "index",
  layout: "index",
  components: {
    CommonTemplate,
    CommonLinks,
    CommonHearder,
  },
  head: {
    title: "启山智软-商城源码，商城系统，小程序商城",
    meta: [
      {
        name: "keywords",
        content:
          "启山智软,商城源码,开源商城系统,在线商城,源码,小程序商城源码,B2C商城源码,B2B2C商城源码,JAVA商城源码,多商户商城源码,在线商城源码,社区团购源码,O2O商城源码,S2B2C商城源码,app商城源码",
      },
      {
        name: "description",
        content:
          "启山智软提供最新最全的商城源码下载，在线商城系统源码分享等，帮助开发者快速搭建个性化在线商城。所有商城源码均为开源源码，包含B2C商城、B2B2C商城、S2B2C商城、O2O商城、社区团购等多种商城源码。快来选择适合的商城源码，开启你的电商商城之旅吧！",
      },
    ],
  },
  async asyncData({ store }) {
    //查询所有导航
    const resp = await queryNavList();
    const navarrs = resp.data.data || [];
    const product_Arrs = resp.data.data[1].children;
    //首页默认没有id 所默认取第一个为首页
    const res = await queyHtmlMoudle(navarrs[0].id);
    const navList = res.data.data || [];

    // console.log(
    //   navList
    //     .filter((item) => item.dynamicElement.length > 0)
    //     .map((item) => item.dynamicElement)
    // );
    store.commit("changeindexArr", navList);

    const resp1 = await footerNav();
    const footer = resp1.data.data;

    store.commit("changeTel", resp1.data.data.telephone);
    const friendlyLinks = footer.friendlyLinks[0].title;
    return {
      navList,
      product_Arrs,
      navarrs,
      friendlyLinks,
      footer,
    };
  },
  data() {
    return {
      isBottom: false,
    };
  },
};
