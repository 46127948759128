
module.exports = {
  apps: [
    {
      name: 'localhost',  //名称可以自定义
      exec_mode: 'cluster',
      instances: 'max', 
      script: './node_modules/nuxt/bin/nuxt.js',
      args: 'start'
    }
  ]
}