
export default {
  //接收对象参数
  props: {
    listObject: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      active: 0,
      detailimg: "",
    };
  },
  methods: {
    //点击切换
    getIndex(index) {
      this.active = index;
      this.detailimg = this.listObject.dynamicElement[index].mainImage;
    },
  },
  mounted() {
    //默认取第一张图
    this.detailimg =
      this.listObject.dynamicElement.length > 0
        ? this.listObject.dynamicElement[this.active].mainImage
        : "";
  },
};
