
import request from "../utils/request";

//查询底部内容列表
export function footerNav() {
  return request({
    url: `/pc/bottom-navigation`,
    method: 'get'
  })
}
