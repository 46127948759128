
export default {
  //接收对象参数
  props: {
    listObject: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      bg: this.$myPlugin.imgOssUrl + "history_time_bg.png",
      a06bg: this.$myPlugin.imgOssUrl + "a06_bg.png",
    };
  },
  methods: {},
};
