
import { footerNav } from "@/api/footer";
export default {
  data() {
    return {
      footer: {},
      links: [], //底部链接
      plainText: [], ///底部文本
      wxcode: "",
    };
  },
  methods: {
    footerNav() {
      footerNav().then((res) => {
        if (res.data.code == 200) {
          this.footer = res.data.data;
          this.wxcode = this.footer.qrCode[0].wxcode;
          this.$store.commit("changeTel", res.data.data.telephone);
          this.$store.commit("changeWxcode", res.data.data.qrCode[0].wxcode);
        }
      });
    },
    changeicon(index) {
      this.wxcode = this.footer.qrCode[index].wxcode;
    },
  },
  mounted() {
    this.footerNav();
  },
};
