
import { getLoglist } from "@/api/index";
export default {
    //接收对象参数
    props: {
        listObject: {
            type: Object,
            default: () => { }
        }
    },
    data() {
        return {
            acitveId: '',//选中的id
            borb: 'borff',
            borblack: '',
            logList:{},
            active: 0
        };
    },
    methods: {
        getLoglists() {
            const params = 'productId/' + this.listObject.navigationFrontList.product + ''
            getLoglist(params)
                .then((res) => {
                    this.logList = res.data.data
                })
                .catch((err) => {
                    console.log(err)
                });
        },
    },
    mounted() {
        this.getLoglists()
    },
};
