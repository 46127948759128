
//表格数据引入

export default {
  name: "",
  props: {
    demoarrs: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      iconsrc: "product_icon_b2c.png",
      arrs: [],
      active: 0,
      codeUrl: "",
      linkurlArr: [],
      codearr: [],
    };
  },
  methods: {
    // 点击演示表格切换操作
    changeTab(index) {
      this.codeUrlarr = this.demoarrs[index].qrCode;
      this.active = index;
      this.linkurlArr = this.demoarrs[index].listFrontVOList;
      this.codearr = this.demoarrs[this.active].qrCode;
    },
  },
  mounted() {
    // this.demoarrs = this.demoarrs[this.active].qrCode
    this.linkurlArr = this.demoarrs[this.active].listFrontVOList;
    this.codearr = this.demoarrs[this.active].qrCode;
  },
};
