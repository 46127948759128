import { render, staticRenderFns } from "./common-template.vue?vue&type=template&id=7568078a"
import script from "./common-template.vue?vue&type=script&lang=js"
export * from "./common-template.vue?vue&type=script&lang=js"
import style0 from "./common-template.vue?vue&type=style&index=0&id=7568078a&prod&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CommonCompany: require('/73app-view/components/common-company.vue').default,CommonRectangle: require('/73app-view/components/common-rectangle.vue').default,CommonHeightChangetab: require('/73app-view/components/common-height-changetab.vue').default,CommonProductGridicon: require('/73app-view/components/common-product-gridicon.vue').default,CommonSesureBox: require('/73app-view/components/common-sesure-box.vue').default,CommonTxtImg: require('/73app-view/components/CommonTxtImg.vue').default,CommonPartners: require('/73app-view/components/common-partners.vue').default,CommonServiceGrid: require('/73app-view/components/common-service-grid.vue').default,CommonProductGrid: require('/73app-view/components/common-product-grid.vue').default,CommonGridBox: require('/73app-view/components/common-grid-box.vue').default,CommonTabover: require('/73app-view/components/common-tabover.vue').default,CommonB05: require('/73app-view/components/common-B05.vue').default,CommonSwiperBanner: require('/73app-view/components/common-swiper-banner.vue').default,CommonLogList: require('/73app-view/components/common-logList.vue').default,CommonDemoUrl: require('/73app-view/components/common-demoUrl.vue').default,CommonNewGrid: require('/73app-view/components/common-new-grid.vue').default,CommonCompanyHistory: require('/73app-view/components/common-company-history.vue').default,CommonMap: require('/73app-view/components/common-map.vue').default,CommonGdmap: require('/73app-view/components/common-gdmap.vue').default})
