export const state = () => ({
  telephone: 18158554030,
  wxcode:
    "https://medusa-small-file-1253272780.cos.ap-shanghai.myqcloud.com/official-website/20231017/b84b88da2b5d4dc78a5f1dfe7d2bca3c.jpg",
  swiperArr: [],
  indexArr: [], //根据导航id查询id数据对象
  newtitle: "", //新闻快标题
  productIds: [],
  productTitle: "", //产品详情标题
  isBottom: false,
});
export const getters = {
  getToken(state) {
    return state.token;
  },
};
export const mutations = {
  setProductIds(state, val) {
    state.productIds = val;
  },
  changeTel(state, val) {
    state.telephone = val;
  },
  changeWxcode(state, val) {
    state.wxcode = val;
  },
  changeindexArr(state, val) {
    state.indexArr = val;
  },
  changeIsBottom(state, val) {
    state.isBottom = val;
  },
};
export const actions = {
  //TODO ajax here
};
