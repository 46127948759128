
export default {
  //接收对象参数
  props: {
    listObject: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      active: 0,
      desTitle: "品牌连锁店",
      subtitle: "品牌连锁店",
      describe:
        "针对区域性品牌零售直营和连锁企业，提供线上商城和线下门店服务终端，结合商品、会员、订单、营销等模块，实现企业线上线下一体化运营，全面提升企业全链路数字化运营能力，有效拓流锁客",
      iconarrs: [
        { id: 0, name: "在线商城", icon: "svg-12" },
        { id: 1, name: "门店管理", icon: "svg-11" },
        { id: 2, name: "供应商管理", icon: "svg-10" },
        { id: 3, name: "营销活动", icon: "svg-9" },
      ],
      tabChangeArrs: [
        {
          id: 0,
          title: "品牌连锁店",
          subtitle: "品牌连锁店",
          describe:
            "针对区域性品牌零售直营和连锁企业，提供线上商城和线下门店服务终端，结合商品、会员、订单、营销等模块，实现企业线上线下一体化运营，全面提升企业全链路数字化运营能力，有效拓流锁客",
          arr: [
            { id: 0, name: "在线商城", icon: "svg-12" },
            { id: 1, name: "门店管理", icon: "svg-11" },
            { id: 2, name: "供应商管理", icon: "svg-10" },
            { id: 3, name: "营销活动", icon: "svg-9" },
          ],
        },
        {
          id: 1,
          title: "数字新零售",
          subtitle: "数字新零售",
          describe:
            "全渠道引流拓客，有效整合资源，让线上商城与门店以及导购互通，更契合消费者的丰富营销工具以及会员权益精细化管理，支持到店消费、线上下单、配送到家等服务，全面提升客户购物体验，增加用户粘性，多维度实现品牌的数字化经营，快速打通“新零售”营销生态链",
          arr: [
            { id: 0, name: "会员管理", icon: "svg-8" },
            { id: 1, name: "商品管理", icon: "svg-7" },
            { id: 2, name: "营销工具", icon: "svg-9" },
            { id: 3, name: "外卖配送", icon: "svg-6" },
          ],
        },
        {
          id: 2,
          title: "供应链解决方案",
          subtitle: "供应链解决方案",
          describe:
            "有效整合上游供应链资源，搭建结合供应商的新零售平台，为渠道商/采购商提供海量货源，渠道商/采购商上架到店铺，再通过平台丰富的营销工具，为终端消费者提供服务。从而提升供应链效率，降低商家成本并实时满足消费者多维度商品需求",
          arr: [
            { id: 0, name: "供应链", icon: "svg1" },
            { id: 1, name: "一键铺货", icon: "svg-5" },
            { id: 2, name: "商品采购", icon: "svg-4" },
            { id: 3, name: "库存盘点", icon: "svg-3" },
          ],
        },
        {
          id: 3,
          title: "分销裂变",
          subtitle: "分销裂变",
          describe:
            "深耕市场营销，深度融合了多种热门玩法，通过分销返佣模式，激励各分销商发展自己的分销员，实现全员推广，快速搭建裂变分销、零售一体化商城体系，全面打开销路，促进商品销量持续飙升",
          arr: [
            { id: 0, name: "多级分销", icon: "svg-2" },
            { id: 1, name: "返佣设置", icon: "svg-1" },
            { id: 2, name: "在线商城", icon: "svg-12" },
            { id: 3, name: "智能调价", icon: "svg" },
          ],
        },
      ],
    };
  },
  methods: {
    //移动切换
    changeTab(index) {
      this.active = index;
      this.subtitle = this.listObject.dynamicElement[index].title;
      this.describe = this.listObject.dynamicElement[index].illustrate;

      this.iconarrs = this.tabChangeArrs[index].arr;
      // this.iconarrs = this.listObject.dynamicElement[index].childList  接口目前返回的数据有问题
    },
  },
  mounted() {
    // this.iconarrs = this.listObject.dynamicElement[0].childList
    this.iconarrs = this.tabChangeArrs[0].arr;
  },
};
