
export default {
  props: {
    listObject: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      active: 0,
      tab_status: false,
      tab_Arrs: [],
    };
  },
  methods: {
    show(index) {
      this.active = index;
      this.tab_status = true;
    },
  },
  mounted() {},
};
