
export default {
  name: "CommonLinks",
  props: {
    footer: {
      type: Object,
      default: () => {},
    },
    friendlyLinks: {
      type: String,
      default: "",
    },
  },
  computed: {
    isBottom() {
      return this.$store.state.isBottom;
    },
  },
};
