
import { allNewList } from "@/api/news_list";
import CommonCompany from "@/components/common-company.vue";
import CommonSesureBox from "@/components/common-sesure-box.vue";
import CommonTxtImg from "~/components/CommonTxtImg.vue";
import CommonPartners from "@/components/common-partners.vue";
import CommonServiceGrid from "@/components/common-service-grid.vue";
import CommonProductGrid from "@/components/common-product-grid.vue";
import CommonGridBox from "@/components/common-grid-box.vue";
import CommonTabover from "@/components/common-tabover.vue";
import CommonB05 from "@/components/common-B05.vue";
import CommonSwiperBanner from "@/components/common-swiper-banner.vue";
import CommonLogList from "@/components/common-logList.vue";
import CommonDemoUrl from "@/components/common-demoUrl.vue";
import CommonNewGrid from "@/components/common-new-grid.vue";
import CommonCompanyHistory from "@/components/common-company-history.vue";
import CommonMap from "@/components/common-map.vue";
import CommonGdmap from "@/components/common-gdmap.vue";
import CommonRectangle from "@/components/common-rectangle.vue";
import CommonHeightChangetab from "@/components/common-height-changetab.vue";
import CommonProductGridicon from "@/components/common-product-gridicon.vue";

export default {
  name: "common-template",
  components: {
    CommonCompany,
    CommonSesureBox,
    CommonTxtImg,
    CommonPartners,
    CommonServiceGrid,
    CommonProductGrid,
    CommonGridBox,
    CommonTabover,
    CommonB05,
    CommonSwiperBanner,
    CommonLogList,
    CommonDemoUrl,
    CommonNewGrid,
    CommonCompanyHistory,
    CommonMap,
    CommonGdmap,
    CommonRectangle,
    CommonHeightChangetab,
    CommonProductGridicon,
  },
  props: {
    navList: {
      type: Array,
      default: () => [],
    },
    productTitle: {
      type: String,
      default: "",
    },
    // 导航栏列表
    navarrs: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      headbgStatus: false, //顶部背景颜色状态
      arrs_type: [],
      news_arrs: [],
    };
  },
  methods: {
    //新闻模板
    newList() {
      allNewList()
        .then((res) => {
          this.arrs_type = res.data.data;
          this.news_arrs = res.data.data.splice(1, this.arrs_type.length - 1);
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
  },
  mounted() {
    //新闻模板
    this.newList();
  },
};
