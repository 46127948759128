
export default {
  name: "common-gdmap",
   //接收对象参数
    props:{
    listObject: {
      type: Object,
      default: () => {}
    }
    },
  data: function () {
    return {
      telephone:'',//18158554030
      zoom: 17,//地图缩放比例
      mapCenter: [121.489551, 29.936806] //经纬度坐标
    };
  },
  mounted(){

  }
};
