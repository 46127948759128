

export default {
	props:{
	product_Arrs: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
		active:0,
		product_status:false,
		};
  },
  methods: {
	show(index){
		this.active =index
		this.product_status = true
	},
  toDetail(e){
    sessionStorage.setItem('isIcon',true)
    sessionStorage.setItem('acitveId','')
  }
  },
  mounted() {

  },
};
