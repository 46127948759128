
export default {
  //接收对象参数
  props: {
    listObject: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      urlList: [], // 网址对象集合
      demourl: [],
      qrCode: [], // 小程序二维码对象集合
    };
  },
  methods: {
    getList() {
      const id = this.listObject.product;
      if (this.$store.state.productIds) {
        this.urlList = this.$store.state.productIds.filter(function (item) {
          return id === item.id;
        });
        this.demourl = this.urlList[0].listFrontVOList;
        this.qrCode = this.urlList[0].qrCode;
      }
    },
  },
  mounted() {
    //查询演示网址
    this.getList();
  },
};
