
import { queryDemoUrl, queryNavList } from "@/api/header";
import { queyHtmlMoudle } from "@/api/index";
import CommonNavBox from "@/components/common-nav-box.vue";

export default {
  components: {
    CommonNavBox,
  },
  props: {
    ssrNavarrs: {
      type: Array,
      default: () => [],
    },
    ssrProduct_Arrs: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isIcon: false,
      acitveId: "", //选中的id
      borb: "borff",
      borblack: "",
      headbgStatus: false,
      headbg: false, //控制顶部背景色
      showLogin: false, //是否显示登录弹窗
      shoowTab: false, //演示案例表格展示
      showTable: false, //演示表格组件
      showOut: false, //显示退出登录
      showBack: false,
      homeStatus: false,
      isShowProduct: false,
      demoarrs: [],
      navarrs: [],
      product_Arrs: [],
      navList: [],
      swiperArr: [],
      active: 0,
    };
  },
  methods: {
    // 有2级菜单的数据填充
    getChildrenNav(e) {
      this.isShowProduct = true;
      this.product_Arrs = e;
    },
    todetail(e) {
      this.acitveId = e.id;
      this.acitveId = sessionStorage.setItem("acitveId", e.id);
      sessionStorage.setItem("isIcon", false);
    },
    isTable(e) {
      this.showTable = e;
    },
    userLogin() {
      this.showLogin = true;
    },
    //关闭登录弹窗
    closeLogin(e) {
      this.showLogin = e;
    },
    backTop() {
      document.scrollTo(0, 0);
    },
    listenerProduct() {
      // 监听，除了点击自己，点击其他地方将自身隐藏
      document.addEventListener("mousemove", (e) => {
        const contextMenuBox = document.getElementById("box");
        if (contextMenuBox) {
          if (!contextMenuBox.contains(e.target)) {
            this.isShowProduct = false;
          }
        }
      });
    },
    listenerTable() {
      // 演示表格展示/隐藏
      document.addEventListener("mousemove", (e) => {
        const contextTable = document.getElementById("boxtable");
        if (contextTable) {
          if (!contextTable.contains(e.target)) {
            this.showTable = false;
          }
        }
      });
    },
    handleScrolls() {
      //获取滚动时的高度
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop > 10) {
        this.headbgStatus = true;
        this.borb = "bor-black"; //导航边框色
      } else {
        this.headbgStatus = false;
        this.borb = "borff"; //导航边框色
      }
    },
    queryDemoUrl() {
      queryDemoUrl()
        .then((res) => {
          this.demoarrs = res.data.data;
          this.$store.commit("setProductIds", res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //根据导航id查询页面模板
    queyHtmlMoudle(id) {
      queyHtmlMoudle(id)
        .then((res) => {
          this.navList = res.data.data;
          this.$store.commit("changeindexArr", this.navList);
        })
        .catch((err) => {});
    },
    //查询所有导航
    queryNavList() {
      queryNavList()
        .then((res) => {
          this.navarrs = res.data.data;
          this.product_Arrs = res.data.data[1].children;
          //首页默认没有id 所默认取第一个为首页
          if (this.$route.path == "/") {
            this.queyHtmlMoudle(this.navarrs[0].id);
          }
        })
        .catch((err) => {});
    },
  },

  mounted() {
    window.addEventListener("scroll", this.handleScrolls, true);
    //查询导航
    this.queryNavList();
    //查询产品信息
    this.listenerProduct();
    //演示账号
    this.listenerTable();
    //查询地址isIcon
    this.queryDemoUrl();
    if (sessionStorage.getItem("acitveId")) {
      this.acitveId = sessionStorage.getItem("acitveId");
    }
    if (sessionStorage.getItem("isIcon")) {
      this.isIcon = sessionStorage.getItem("isIcon");
    }
  },
  destroyed() {
    document.removeEventListener("scroll", this.handleScrolls, true);
  },
};
