import request from "../utils/request";

//查询页面模板列表
export function queyHtmlMoudle(id) {
  if (id) {
    return request({
      url: `/pc/navigation/${id}`,
      method: "get",
    });
  } else {
    return new Promise((resolve, reject) => {
      reject("id is null");
    });
  }
}

//查询日志
export function getLoglist(productId) {
  return request({
    url: `/pc/product-update-log/${productId}`,
    method: "get",
  });
}

//查询演示网址
export function queryUrl(id) {
  return request({
    url: `/pc/product-demo-url/${id}`,
    method: "get",
  });
}
