
export default {
  methods: {
    // 防抖函数
    debounce(fn, delay) {
      let timer = null;
      return function () {
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
          fn.apply(this, arguments);
        }, delay);
      };
    },
    scrollToBottom(e) {
      this.debounce(this.handleScroll, 100)(e);
    },
    handleScroll(e) {
      const { scrollTop, clientHeight, scrollHeight } = e.target;
      const isBottom = scrollTop + clientHeight >= scrollHeight - 5;
      this.$store.commit("changeIsBottom", isBottom);
    },
  },
};
