import request from "../utils/request";

//查询产品演示地址
export function queryDemoUrl(data) {
  return request({
    url: `/pc/product-demo-url`,
    method: 'get'
  })
}

//查询导航all
export function queryNavList(query) {
  return request({
    url: `/pc/navigation`,
    method: 'get',
    params: query
  })
}

//根据导航id查询页面内容
export function queryNavDetail(id) {
  return request({
    url: `/pc/navigation/${id}`,
    method: 'get'
  })
}