
export default {
  //接收对象参数
  props: {
    listObject: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  methods: {},
};
