
export default {
  props: {
    title: {
      type: String,
      default: () => "",
    },
    arrs_type: {
      type: Array,
      default: () => [],
    },
    news_arrs: {
      type: Array,
      default: () => [],
    },
  },
};
