
export default {
  props: {
    listObject: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showService: false,
      swiperOption: {
        circular: true,
        loop: false,
        autoplay: {
          delay: 4500,
          disableOnInteraction: false, //用户操作轮播后继续动画自动轮播
        },
        slidesPerView: "auto",
        centeredSlides: true,
        spaceBetween: 0,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          dynamicBullets: false,
        },
        navigation: {
          nextEl: ".swiper-button-next", // 下一页dom节点
          prevEl: ".swiper-button-prev", // 前一页dom节点
        },
      },
    };
  },
  methods: {
    showForm() {
      if (this.showService) {
        this.showService = false;
      } else {
        this.showService = true;
      }
    },
    listenerService() {
      // 监听，除了点击自己，点击其他地方将自身隐藏
      document.addEventListener("click", (e) => {
        const contextMenuBox = document.getElementById("txchat");
        if (contextMenuBox) {
          if (!contextMenuBox.contains(e.target)) {
            this.showService = false;
          } else {
            this.showService = true;
          }
        }
      });
    },
    tabChange(e) {
      console.log(e + "看");
    },
  },
  mounted() {
    this.listenerService();
    //banner图数量大于1,才会开启动画
    if (this.listObject.dynamicElement.length > 1) {
      this.swiperOption.loop = true;
    }
  },
};
