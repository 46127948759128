import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _4a2c714c = () => interopDefault(import('../pages/successCases.vue' /* webpackChunkName: "pages/successCases" */))
const _7eae8a84 = () => interopDefault(import('../pages/news/list.vue' /* webpackChunkName: "pages/news/list" */))
const _5ffaeb36 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _173e07c8 = () => interopDefault(import('../pages/news/detail/_id.vue' /* webpackChunkName: "pages/news/detail/_id" */))
const _867025a2 = () => interopDefault(import('../pages/productDetail/_id.vue' /* webpackChunkName: "pages/productDetail/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/successCases",
    component: _4a2c714c,
    name: "successCases"
  }, {
    path: "/news/list",
    component: _7eae8a84,
    name: "news-list"
  }, {
    path: "/",
    component: _5ffaeb36,
    name: "index"
  }, {
    path: "/news/detail/:id?",
    component: _173e07c8,
    name: "news-detail-id"
  }, {
    path: "/productDetail/:id?",
    component: _867025a2,
    name: "productDetail-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
