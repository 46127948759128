import { render, staticRenderFns } from "./common-hearder.vue?vue&type=template&id=b9577d22&scoped=true"
import script from "./common-hearder.vue?vue&type=script&lang=js"
export * from "./common-hearder.vue?vue&type=script&lang=js"
import style0 from "./common-hearder.vue?vue&type=style&index=0&id=b9577d22&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b9577d22",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CommonNavBox: require('/73app-view/components/common-nav-box.vue').default,CommonTableDemo: require('/73app-view/components/common-table-demo.vue').default,CommonLogin: require('/73app-view/components/common-login.vue').default})
