
import { addService } from "@/api/news_list";
export default {
  data() {
    return {
      msgstatus: false,

      formStatus: false,
      addform: {
        //添加预约
        mobilePhone: "",
      },
      showBack: false, //是否显示返回顶部按钮
      list: [],
      items: [],
      scrollTop: 0,
      telephone: "18158554030",
      wxcode:
        "https://medusa-small-file-1253272780.cos.ap-shanghai.myqcloud.com/static/official/index/service_wechat.jpg",
      msg: "预约成功",
      success: false,
    };
  },
  created() {},
  // async asyncData({ $axios }) {
  //   let res = await $axios.get("http://testapi.xuexiluxian.cn/api/slider/getSliders");
  //   let list = res.data.data.list;
  //   return {
  //     list,
  //   };
  // },
  methods: {
    backTop() {
      const that = this;
      let timer = setInterval(() => {
        let ispeed = Math.floor(-that.scrollTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop =
          that.scrollTop + ispeed;
        if (that.scrollTop === 0) {
          clearInterval(timer);
        }
      }, 16);
    },

    handleScroll() {
      //获取滚动时的高度
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop > 60) {
        this.showBack = true;
      } else {
        this.showBack = false;
      }
    },
    showForm() {
      if (this.formStatus) {
        this.formStatus = false;
      } else {
        this.formStatus = true;
      }
    },
    listenerService() {
      // 监听，除了点击自己，点击其他地方将自身隐藏
      document.addEventListener("click", (e) => {
        const contextMenuBox = document.getElementById("formCard");
        if (contextMenuBox) {
          if (!contextMenuBox.contains(e.target)) {
            this.formStatus = false;
            this.addform.mobilePhone = "";
          } else {
            this.formStatus = true;
            this.addform.mobilePhone = "";
          }
        }
      });
    },
    changetel() {
      if (this.addform.mobilePhone.length > 10) {
        this.msgstatus = false;
      }
    },
    showservice() {
      setTimeout(() => {
        window.qimoChatClick();
      }, 20000);
    },
    hidemsg() {
      setTimeout(() => {
        this.success = false;
        // this.formStatus=false
      }, 2000);
    },

    addServices() {
      //   const regMobile = /^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$/;
      // if (!regMobile.test(this.addform.mobilePhone)) {
      //     this.success=true
      //     this.msg='手机号格式不正确'
      //     this.hidemsg()
      //     return false
      //   }
      addService(this.addform)
        .then((res) => {
          this.success = true;
          if (res.data.code === 200) {
            this.addform.mobilePhone = "";
          } else {
            this.msg = "手机号格式不正确";
          }
          this.hidemsg();
        })
        .catch((err) => {
          this.msg = "error";
        });
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
    this.listenerService();
    // this.showservice()
    // this.telephone=localStorage.getItem('telephone')
    // this.wxcode=localStorage.getItem('wxcode')
  },
  destroyed() {
    document.removeEventListener("scroll", this.handleScroll, true);
  },
};
