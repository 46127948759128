import { render, staticRenderFns } from "./index.vue?vue&type=template&id=1a7af7bb"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=1a7af7bb&prod&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CommonHearder: require('/73app-view/components/common-hearder.vue').default,CommonTemplate: require('/73app-view/components/common-template.vue').default,CommonLinks: require('/73app-view/components/CommonLinks.vue').default})
