import axios from "axios";
import qs from "qs";

axios.defaults.withCredentials = true;
const service = axios.create({
  // api 的 base_url http://192.168.1.118:8778  https://official.bgniao.cn/official
  baseURL:
    process.env.NODE_ENV === "production"
      ? "https://www.73app.cn/official"
      : "https://www.73app.cn/official",
  timeout: 15000, // request timeout
});

// 请求方式
service.interceptors.request.use(
  (config) => {
    config.headers["Accept-Language"] = "zh_CN";
    //只针对get方式进行序列化
    if (config.method === "get") {
      config.paramsSerializer = function (params) {
        return qs.stringify(params, { arrayFormat: "repeat" });
      };
    }
    if (config.method === "post") {
      config.headers["Content-Type"] = "application/json";
    }

    return config;
  },
  (error) => {
    // Do something with request error
    console.log(error); // for debug
    Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  (response) => {
    const status = response.status;
    if (status === 200) {
      return response;
    } else {
      return Promise.reject(response);
    }
  },
  (error) => {
    console.log("err" + error); // for debug
  }
);

export default service;
