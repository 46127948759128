
export default {
  data() {
    return {};
  },
  created() {},
  methods: {
    closeLogin() {
      this.$emit("closeLogin", false);
    },
  },
};
