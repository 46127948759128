export const CommonLinks = () => import('../../components/CommonLinks.vue' /* webpackChunkName: "components/common-links" */).then(c => wrapFunctional(c.default || c))
export const CommonTxtImg = () => import('../../components/CommonTxtImg.vue' /* webpackChunkName: "components/common-txt-img" */).then(c => wrapFunctional(c.default || c))
export const CommonA05 = () => import('../../components/common-A05.vue' /* webpackChunkName: "components/common-a05" */).then(c => wrapFunctional(c.default || c))
export const CommonB05 = () => import('../../components/common-B05.vue' /* webpackChunkName: "components/common-b05" */).then(c => wrapFunctional(c.default || c))
export const CommonCompanyHistory = () => import('../../components/common-company-history.vue' /* webpackChunkName: "components/common-company-history" */).then(c => wrapFunctional(c.default || c))
export const CommonCompany = () => import('../../components/common-company.vue' /* webpackChunkName: "components/common-company" */).then(c => wrapFunctional(c.default || c))
export const CommonDemoUrl = () => import('../../components/common-demoUrl.vue' /* webpackChunkName: "components/common-demo-url" */).then(c => wrapFunctional(c.default || c))
export const CommonEmpty = () => import('../../components/common-empty.vue' /* webpackChunkName: "components/common-empty" */).then(c => wrapFunctional(c.default || c))
export const CommonFooter = () => import('../../components/common-footer.vue' /* webpackChunkName: "components/common-footer" */).then(c => wrapFunctional(c.default || c))
export const CommonGdmap = () => import('../../components/common-gdmap.vue' /* webpackChunkName: "components/common-gdmap" */).then(c => wrapFunctional(c.default || c))
export const CommonGridBox = () => import('../../components/common-grid-box.vue' /* webpackChunkName: "components/common-grid-box" */).then(c => wrapFunctional(c.default || c))
export const CommonHearder = () => import('../../components/common-hearder.vue' /* webpackChunkName: "components/common-hearder" */).then(c => wrapFunctional(c.default || c))
export const CommonHeightChangetab = () => import('../../components/common-height-changetab.vue' /* webpackChunkName: "components/common-height-changetab" */).then(c => wrapFunctional(c.default || c))
export const CommonLogList = () => import('../../components/common-logList.vue' /* webpackChunkName: "components/common-log-list" */).then(c => wrapFunctional(c.default || c))
export const CommonLogin = () => import('../../components/common-login.vue' /* webpackChunkName: "components/common-login" */).then(c => wrapFunctional(c.default || c))
export const CommonMap = () => import('../../components/common-map.vue' /* webpackChunkName: "components/common-map" */).then(c => wrapFunctional(c.default || c))
export const CommonNavBox = () => import('../../components/common-nav-box.vue' /* webpackChunkName: "components/common-nav-box" */).then(c => wrapFunctional(c.default || c))
export const CommonNewGrid = () => import('../../components/common-new-grid.vue' /* webpackChunkName: "components/common-new-grid" */).then(c => wrapFunctional(c.default || c))
export const CommonOutLogin = () => import('../../components/common-outLogin.vue' /* webpackChunkName: "components/common-out-login" */).then(c => wrapFunctional(c.default || c))
export const CommonPartners = () => import('../../components/common-partners.vue' /* webpackChunkName: "components/common-partners" */).then(c => wrapFunctional(c.default || c))
export const CommonProductGrid = () => import('../../components/common-product-grid.vue' /* webpackChunkName: "components/common-product-grid" */).then(c => wrapFunctional(c.default || c))
export const CommonProductGridicon = () => import('../../components/common-product-gridicon.vue' /* webpackChunkName: "components/common-product-gridicon" */).then(c => wrapFunctional(c.default || c))
export const CommonRectangle = () => import('../../components/common-rectangle.vue' /* webpackChunkName: "components/common-rectangle" */).then(c => wrapFunctional(c.default || c))
export const CommonServiceGrid = () => import('../../components/common-service-grid.vue' /* webpackChunkName: "components/common-service-grid" */).then(c => wrapFunctional(c.default || c))
export const CommonService = () => import('../../components/common-service.vue' /* webpackChunkName: "components/common-service" */).then(c => wrapFunctional(c.default || c))
export const CommonSesureBox = () => import('../../components/common-sesure-box.vue' /* webpackChunkName: "components/common-sesure-box" */).then(c => wrapFunctional(c.default || c))
export const CommonSucessdemo = () => import('../../components/common-sucessdemo.vue' /* webpackChunkName: "components/common-sucessdemo" */).then(c => wrapFunctional(c.default || c))
export const CommonSwiperBanner = () => import('../../components/common-swiper-banner.vue' /* webpackChunkName: "components/common-swiper-banner" */).then(c => wrapFunctional(c.default || c))
export const CommonTableDemo = () => import('../../components/common-table-demo.vue' /* webpackChunkName: "components/common-table-demo" */).then(c => wrapFunctional(c.default || c))
export const CommonTabover = () => import('../../components/common-tabover.vue' /* webpackChunkName: "components/common-tabover" */).then(c => wrapFunctional(c.default || c))
export const CommonTemplate = () => import('../../components/common-template.vue' /* webpackChunkName: "components/common-template" */).then(c => wrapFunctional(c.default || c))
export const CommonTopGrid = () => import('../../components/common-top-grid.vue' /* webpackChunkName: "components/common-top-grid" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
