import request from "../utils/request";

// 查询新闻列表ids:[id]不分页
export function getAllNewsList() {
  return request({
    url: `/pc/news/all`,
    method: "get",
  });
}
//查询新闻列表
export function newList(data) {
  return request({
    url: `/pc/news/${data}`,
    method: "get",
  });
}

//查询最新的新闻模块
export function allNewList() {
  return request({
    url: `/pc/news`,
    method: "get",
  });
}

//查询新闻详情
export function newDetail(id, params) {
  return request({
    url: `/pc/news/${id}`,
    method: "get",
    params,
  });
}

//添加预约客户
export function addService(data) {
  return request({
    url: `/pc/custom-followed`,
    method: "post",
    data,
  });
}

//查询近期文章
export function recentNews() {
  return request({
    url: `/pc/news/recent`,
    method: "get",
  });
}
