
export default {
  // 接收参数
  props: {
    listObject: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      active: 0,
      showWechat: false, //产品试用
      product_status: false,
      productbg: this.$myPlugin.imgOssUrl + "section2_bg.png",
      product_Arrs: [
        {
          id: 0,
          link: "productDetail/1710893853792112641",
          icon: "product_icon_b2c.png",
          title: "B2C单商户商城",
          label: "社交电商",
          subtitle: "全渠道多场景单商户商城",
          coverImg: "biaozhun_3d.jpg",
          desc: "高性能私域营销电商系统，契合私域流量变现闭环交易使用。构建品牌商、零售商属于自己的多渠道、多场景的线上单商户网店系统",
        },
        {
          id: 1,
          icon: "product_icon_b2b2c.png",
          link: "productDetail/1710893853792112641",
          title: "B2B2C多商户商城",
          label: "社交电商",
          subtitle: "支持自营＋多商家入驻模式",
          coverImg: "pro_duo_3d.jpg",
          desc: "全链条社交型商城系统，类天猫、京东商业盈利模式，企业可构建平台型商城整合多家商家入驻到平台开店，同时也支持自营，满足用户线上多场景交互需求",
        },
        {
          id: 2,
          icon: "product_icon_s2b2c.png",
          link: "productDetail/1710893853792112641",
          title: "S2B2C 供应链商城",
          label: "S2B2C 供应链商城",
          subtitle: "整合上下游资源，打通供应链",
          coverImg: "zsff_3d.jpg",
          desc: "支持供货+批发+一键代发+零售+分销模式，实现上中下游供应链智能一体化运营",
        },
        {
          id: 3,
          icon: "product_icon_o2o.png",
          link: "productDetail/1710893853792112641",
          title: "O2O外卖配送",
          label: "外卖配送",
          subtitle: "数字化赋能配送新时代，提升配送效率",
          coverImg: "java_duo_3d.jpg",
          desc: "线上平台拓展线下销售边界，解决线下多场景经营痛点，产品/服务多渠道覆盖，实现智能化运营，提升效率。可设置配送区域，为商城接入属于自己同城配送管理系统",
        },
        {
          id: 4,
          icon: "product_icon_smart.png",
          link: "productDetail/1710893853792112641",
          title: " SmartShop电商中台",
          label: " SmartShop电商中台",
          subtitle: "更契合市场的多种商业模式",
          coverImg: "mer_3d.jpg",
          desc: "SpringCloud+vue3微服务架构，代码高度解耦。按需加载组件，易操作可轻量级运行，兼容S2B2C、B2B2C、B2C、O2O四种商业模式，可满足多场景业务需求",
        },
        {
          id: 5,
          icon: "product_icon_sqtg.png",
          link: "productDetail/1710893853792112641",
          title: "社区团购",
          label: "社区团购",
          subtitle: "新型社区消费模式",
          coverImg: "java_dan_3d.jpg",
          desc: "突破传统拼团模式，开启“社群+社交+社区”消费新模式，人与人集合模式的新融合",
        },
      ],
    };
  },
  methods: {
    godetail(e) {
      window.open(e.link5 || e.link6, "_blank");
    },
    show(index) {
      this.active = index;
      this.product_status = true;
    },
  },
  mounted() {},
};
